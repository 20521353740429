import React from "react";
import { Grid, Card, CardHeader, Box, Alert } from "@mui/material";
import { ConfigurationList } from "../components/configuration";
import "../styles/deployment.css";
import ConfigurationStats from "../components/configuration/ConfigurationStats";
import { MODULES, MODULE_TEXT } from "../services/Constants";
import { detailsTabsBoxStyle } from "../services/Utils";
import useModule from "../hooks/useModule";

const Configurations = () => {
    const { validate } = useModule();

    if (!validate(MODULES.CONFIGURATION)) {
        return <Box {...detailsTabsBoxStyle}>
            <Alert variant="outlined" severity="warning">
                {MODULE_TEXT.CONFIGURATION} module not enabled for {localStorage.selectedCustomer} customer
            </Alert>
        </Box>
    }

    return (
        <div className="fragmentContainer">
            <Grid container spacing={2}>
                <ConfigurationStats />
                <Grid item xs={12} md={12}>
                    <Card>
                        <CardHeader title="Custom Configuration List"></CardHeader>
                        <ConfigurationList />
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default Configurations;
