import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { IconButton, Dialog, DialogTitle, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FileUpload } from "./index";
import FileUploadDialog from "./FileUploadDialog";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";

const ImageUploadDataTableRowActions = ({ data }) => {
  const {
    row: { uploadStatus },
  } = data || {};
  const [openModal, setOpenModal] = useState(false);
  const [resumeUpload, setResumeUpload] = useState(null);

  const handleResumeUpload = (id) => {
    if (!!id) {
      setOpenModal(true);
      setResumeUpload(id);
    } else {
      setOpenModal(true);
    }
  };

  const handleClose = () => {
    setResumeUpload(null);
    setOpenModal(false);
  };

  return (
    <>
      <div>
        <Button size="small" component={RouterLink} to={`/Images/${data.id}`}>
          Details
        </Button>
        {!(uploadStatus === "Failed" || uploadStatus === "Completed")
          && (<>
            <Check I={CAN.MANAGE} a={SUBJECTS.IMAGE}>
              {<span>|</span>}
              <Button
                size="small"
                onClick={() => handleResumeUpload(data?.id)}
              >
                Upload
              </Button>
            </Check>
          </>)}
      </div>
      <FileUploadDialog
        openModal={openModal}
        handleClose={handleClose}
        resumeUpload={resumeUpload}
      />
    </>
  );
};
export default ImageUploadDataTableRowActions;
