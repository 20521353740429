import React from "react";
import { Grid, Card, CardHeader, Box, Alert } from "@mui/material";
import { DeploymentListTable } from "../components/deployment";
import "../styles/deployment.css";
import DeploymentStats from "../components/deployment/DeploymentStats";
import { MODULES, MODULE_TEXT } from "../services/Constants";
import { detailsTabsBoxStyle } from "../services/Utils";
import useModule from "../hooks/useModule";

const Deployments = () => {
  const { validate } = useModule();

  if (!validate(MODULES.OTA)) {
    return <Box {...detailsTabsBoxStyle}>
      <Alert variant="outlined" severity="warning">
        {MODULE_TEXT.OTA} module not enabled for {localStorage.selectedCustomer} customer
      </Alert>
    </Box>
  }

  return (
    <div className="fragmentContainer">
      <Grid container spacing={2}>
        <DeploymentStats />
        <Grid item xs={12} md={12}>
          <Card>
            <CardHeader title="Deployment List"></CardHeader>
            <DeploymentListTable />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Deployments;
