import React, { useState, useEffect, useCallback } from "react";
import { Button, Paper } from "@mui/material";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Warning, ThumbUpAlt } from "@mui/icons-material";
import { FileUpload } from "./index";
import FileUploadPickerPane from "./FileUploadPickerPane";
import Constants, {
  DEPLOYMENT_PACKAGE_TYPE,
  FILE_TYPES,
} from "../../services/Constants";
import { useToast } from "../toast";
import ConfirmAbortUpload from "./ConfirmAbortUpload";

function FileUploadDialog({ handleClose, openModal, resumeUpload }) {
  let { UPLOAD_STATUS } = Constants;
  const { addToast } = useToast();
  const [isJsonValid, setIsJsonValid] = useState(false);
  const [uploadInstance, setUploadInstance] = useState(null);
  const [imageUploadActionLoading, setImageUploadActionLoading] =
    useState(false);
  const PrettyPrintJson = ({ data }) => {
    const isJson = (str) => {
      try {
        JSON.parse(JSON.stringify(str));
      } catch (e) {
        //Error
        //JSON is not okay
        setIsJsonValid(false);
        return (
          <>
            <Warning color="warning" />
            <div>Manifest is invalid</div>
          </>
        );
      }
      setIsJsonValid(true);
      return (
        <>
          <ThumbUpAlt color="success" />
          <div>Valid Manifest</div>
        </>
      );
    };
    return (
      <div style={{ width: "100%" }}>
        <Paper elevation={0} sx={{ overflow: "auto", maxHeight: "428px" }}>
          <code>
            <pre
              style={{
                whiteSpace: "break-spaces",
                backgroundColor: "#dedede",
                borderRadius: "5px",
                fontSize: 12,
              }}
            >
              {JSON.stringify(data, null, 2)}
            </pre>
          </code>
        </Paper>
        <div
          style={{
            marginTop: "1em",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isJson(data)}
        </div>
      </div>
    );
  };
  const [step, setStep] = useState("0");
  const [jsonFile, setJsonFile] = useState(null);
  const [packageType, setPackageType] = useState(
    DEPLOYMENT_PACKAGE_TYPE.SMARTCORE
  );
  const [uploadStatus, setUploadStatus] = useState(null);
  const [uploadProgress, setUploadProgress] = useState({
    loaded: 0,
    total: 100,
  });
  const [showCancelConfirmPopup, setShowCancelConfirmPopup] = useState(false);
  const campaignTypeInputHandler = (event) => {
    const {
      target: { value },
    } = event;
    setPackageType(value);
  };
  const onManifestFileSelected = (selectedFileData) => {
    const fileReader = new FileReader();
    const selected = selectedFileData;
    fileReader.readAsText(selected);
    fileReader.onload = (e) => {
      //console.log("e.target.result", e.target.result);
      try {
        const jsonData = JSON.parse(e?.target?.result);
        setJsonFile(jsonData);
      } catch (e) {
        //Error
        addToast({
          type: "error",
          message: `"${selected?.name}" may not contain a valid Json data!`,
          autoClose: 3000,
        });
        setJsonFile(null);
      }
    };
  };

  const onClose = useCallback(() => {
    setStep("0");
    setJsonFile(null);
    setPackageType(DEPLOYMENT_PACKAGE_TYPE.SMARTCORE);
  }, []);

  useEffect(() => {
    return () => {
      setStep("0");
      onClose();
    };
  }, [onClose]);

  return (
    <>
      <Dialog
        open={openModal}
        maxWidth="md"
        fullWidth
        scroll="paper"
        PaperProps={{
          style: {
            maxHeight: "90%",
          },
        }}
      >
        <DialogTitle>
          Image Upload
          <IconButton
            aria-label="close"
            onClick={() => {
              if (
                uploadStatus === UPLOAD_STATUS.InProgress ||
                uploadStatus === UPLOAD_STATUS.Started
              ) {
                setShowCancelConfirmPopup(true);
              } else {
                handleClose?.();
                onClose();
              }
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {step === "0" && !resumeUpload && (
            <div
              style={{
                marginBottom: "1em",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ width: "256px" }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="campaign-type-label">Package Type</InputLabel>
                  <Select
                    value={packageType}
                    onChange={campaignTypeInputHandler}
                    label="Campaign Type"
                  >
                    {/* <MenuItem value={DEPLOYMENT_PACKAGE_TYPE.OTHERS}>
                      Others
                    </MenuItem> */}
                    <MenuItem value={DEPLOYMENT_PACKAGE_TYPE.SMARTCORE}>
                      Smart Core
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
          {step === "1" && !resumeUpload && (
            <>
              {packageType === DEPLOYMENT_PACKAGE_TYPE.SMARTCORE ? (
                <div style={{ marginBottom: "1em" }}>
                  {jsonFile != null &&
                  typeof jsonFile === "object" &&
                  Object.keys(jsonFile)?.length ? (
                    <PrettyPrintJson data={jsonFile} />
                  ) : (
                    <FileUploadPickerPane
                      onFileSelected={onManifestFileSelected}
                      title="Upload Manifest file for the Image artefact"
                      fileType={FILE_TYPES.JSON}
                      styles={{ height: "156px" }}
                    />
                  )}
                </div>
              ) : (
                <div>
                  <FileUpload
                    packageType={packageType}
                    manifestData={jsonFile}
                    handleClose={handleClose}
                    resumeUpload={resumeUpload}
                    uploadProgress={uploadProgress}
                    setUploadProgress={setUploadProgress}
                    uploadStatus={uploadStatus}
                    setUploadStatus={setUploadStatus}
                    uploadInstance={uploadInstance}
                    setUploadInstance={setUploadInstance}
                    imageUploadActionLoading={imageUploadActionLoading}
                    setImageUploadActionLoading={setImageUploadActionLoading}
                  />
                </div>
              )}
            </>
          )}
          {(step === "2" || !!resumeUpload) && (
            <div>
              <FileUpload
                packageType={packageType}
                manifestData={jsonFile}
                handleClose={handleClose}
                resumeUpload={resumeUpload}
                uploadProgress={uploadProgress}
                setUploadProgress={setUploadProgress}
                uploadStatus={uploadStatus}
                setUploadStatus={setUploadStatus}
                uploadInstance={uploadInstance}
                setUploadInstance={setUploadInstance}
                imageUploadActionLoading={imageUploadActionLoading}
                setImageUploadActionLoading={setImageUploadActionLoading}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {step === "0" && !resumeUpload && (
            <>
              <div></div>
              <Button
                onClick={() => {
                  setStep("1");
                }}
              >
                Next
              </Button>
            </>
          )}
          {step === "1" && !resumeUpload && (
            <>
              {!UPLOAD_STATUS[uploadStatus] || !imageUploadActionLoading ? (
                <Button
                  onClick={() => {
                    setStep("0");
                    setJsonFile(null);
                    setIsJsonValid(false);
                  }}
                >
                  Back
                </Button>
              ) : (
                <div></div>
              )}
              <div id="upload-dialog-footer-area">
                {packageType === DEPLOYMENT_PACKAGE_TYPE.SMARTCORE && (
                  <Button
                    disabled={!isJsonValid}
                    onClick={() => {
                      setStep("2");
                    }}
                  >
                    Next
                  </Button>
                )}
              </div>
            </>
          )}
          {(step === "2" || !!resumeUpload) && (
            <>
              {!UPLOAD_STATUS[uploadStatus] && !imageUploadActionLoading ? (
                <Button
                  onClick={() => {
                    setStep("1");
                  }}
                >
                  Back
                </Button>
              ) : (
                <div></div>
              )}
              <div id="upload-dialog-footer-area"></div>
            </>
          )}
        </DialogActions>
      </Dialog>
      {showCancelConfirmPopup ? (
        <ConfirmAbortUpload
          {...{
            setShowCancelConfirmPopup,
            uploadInstance,
            handleClose,
            onClose,
            showCancelConfirmPopup,
            resumeUpload,
          }}
        />
      ) : null}
    </>
  );
}

export default FileUploadDialog;
