import React, { useState, Fragment, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  Checkbox,
  ListItemText,
  List,
  ListItem,
  DialogContent,
  Dialog,
  DialogTitle,
  IconButton,
  DialogActions,
  Tooltip,
  Skeleton,
} from "@mui/material";
import { green } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useToast } from "../../components/toast";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getListOfProvisioningCsv,
  provisioningSelectedFiles,
} from "../../services/Dashboard";
import CircularProgress from "@mui/material/CircularProgress";
import { OTA_CUSTOMERS } from "../../services/Constants";

const CsvProvisioingList = ({ open, handleClose }) => {
  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [csvList, setCsvList] = useState([]);
  const [checked, setChecked] = useState([]);
  const [disableAllCheckboxes, setDisableAllCheckboxes] = useState(false);
  const onClose = () => {
    setLoading(false);
    setCsvList([]);
    setChecked([]);
    setDisableAllCheckboxes(false);
    queryClient.invalidateQueries([
      "schedulerLogsReport",
      "getlistofcsvforprovisioning"
    ]);
    handleClose();
  };
  const { mutate: scanForFiles, isLoading: scanning } = useMutation(
    getListOfProvisioningCsv,
    {
      onSuccess: (res) => {
        console.log("scan list res,", res);
        const { data } = res;
        setCsvList(data);
        if (localStorage.selectedCustomer === OTA_CUSTOMERS.HD) {
          setDisableAllCheckboxes(true);
          const items = data?.map((i) => i?.name);
          setChecked(items);
        }
      },
      onSettled: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries([
          "schedulerLogsReport",
          "getlistofcsvforprovisioning"
        ]);
      },
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: provisionSelected, isLoading } = useMutation(
    provisioningSelectedFiles,
    {
      onSuccess: (data) => {
        console.log(data);
        addToast({
          type: "success",
          message: `Provisioning completed successfully.`,
          autoClose: 3000,
        });
        onClose();
      },
      onError: (err) => {
        const {
          response: { data: errorData },
        } = err;
        addToast({
          type: "error",
          message: `Provisioning failed.`,
          autoClose: 3000,
        });
      },
      onSettled: () => {
        setLoading(false);
        queryClient.invalidateQueries("schedulerLogsReport");
      },
    }
  );

  const handleToggle = (value) => () => {
    if (disableAllCheckboxes) {
      return;
    }
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const onSubmit = () => {
    setLoading(true);
    if (csvList?.length && checked?.length) {
      const payload = {
        selectedFileNames: [...checked],
      };
      console.log("Provisioning payload -", payload);
      provisionSelected(payload);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    scanForFiles();
    return () => { };
  }, [scanForFiles]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      PaperProps={{
        style: {
          maxHeight: "90%",
          maxWidth: "60%",
          minWidth: "420px",
        },
      }}
    >
      <DialogTitle>
        Provision
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography
          color="text.primary"
          sx={{ mt: 5 }}
          style={{ marginBottom: "18px" }}
        >
          {loading
            ? "Provisioning..."
            : csvList?.length
              ? "Select files to provision"
              : ""}
        </Typography>
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "background.paper",
          }}
        >
          {csvList?.length ? (
            csvList?.map((value) => (
              <ListItem
                style={{
                  display:
                    checked.indexOf(value?.name) === -1 && loading
                      ? "none"
                      : "block",
                  pointerEvents:
                    loading || disableAllCheckboxes ? "none" : "all",
                }}
                key={value?.name}
                // secondaryAction={
                //   <IconButton edge="end" aria-label="comments">
                //     <CommentIcon />
                //   </IconButton>
                // }
                disablePadding
              >
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(value?.name)}
                  dense
                >
                  <ListItemIcon>
                    <>
                      {checked.indexOf(value?.name) !== -1 && loading ? (
                        <Box sx={{ width: "100%" }}>
                          <CircularProgress
                            size={16}
                            sx={{
                              color: green[500],
                              marginTop: "4px",
                            }}
                          />
                        </Box>
                      ) : (
                        <Checkbox
                          edge="start"
                          checked={checked.indexOf(value?.name) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            "aria-labelledby": `checkbox-list-label-${value?.name}`,
                          }}
                          disabled={loading || disableAllCheckboxes}
                        />
                      )}
                    </>
                  </ListItemIcon>
                  <ListItemText
                    id={`checkbox-list-label-${value?.name}`}
                    primary={value?.name}
                  />
                </ListItemButton>
              </ListItem>
            ))
          ) : (
            <>
              {scanning
                ? [1, 2, 3, 4].map((i) => (
                  <Typography key={i} variant="h3">
                    <Skeleton animation="wave" />
                  </Typography>
                ))
                : "No files found."}
            </>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={() => {
            onSubmit();
          }}
          disabled={checked?.length < 1 || loading}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CsvProvisioingList;
