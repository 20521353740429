import axios from "axios";
import Constants from "../services/Constants";
import { constructQueryUrl } from "./Utils";

export const getVehicleData = async () => {
  return await axios.get(Constants.LIST_VEHICLES);
};

export const createGroup = async (payload) => {
  return await axios.post(Constants.CREATE_VEHICLE_GROUP, payload);
};

export const getVehicleListFromCsv = async (payload) => {
  return await axios.post(Constants.GET_VEHICLE_LIST_FROM_CSV, payload);
};

export const updateVehiclesInGroup = async (payload) => {
  return await axios.put(Constants.CREATE_VEHICLE_GROUP, payload);
};

export const deleteVehiclesInGroup = async (payload) => {
  return await axios.put(Constants.DELETE_VEHICLE_GROUP, payload);
};

export const createDynamicGroup = async (payload) => {
  return await axios.post(Constants.CREATE_DYNAMIC_VEHICLE_GROUP, payload);
};

export const updateDynamicGroup = async (payload) => {
  return await axios.post(Constants.UPDATE_DYNAMIC_VEHICLE_GROUP, payload);
};

export const getVehicleGroups = async () => {
  const { data } = await axios.get(Constants.LIST_VEHICLE_GROUPS);
  return data;
};

export const getVehicleGroupsList = async ({ queryKey }) => {
  const [_, page, pageSize, field, sort = "ASC", filterValue, columns] =
    queryKey;
  let query =
    Constants.LIST_VEHICLE_GROUPS +
    `?page=${page}&take=${pageSize}&sortby=${field}&order=${sort?.toUpperCase()}`;

  query = constructQueryUrl({ filterValue, query, columns });
  return await axios.get(query);
};

export const getVehiclesInGroups = async (list) => {
  const url = Constants.LIST_VEHICLES_IN_GROUPS.replace(":groupsList", list);
  return await axios.get(url);
};

export const getGroupMetadata = async (name) => {
  const url = Constants.GET_GROUP_METADATA.replace(":groupName", name);
  return await axios.get(url);
};

export const getVehicleDetails = async ({ queryKey }) => {
  const [_, vehicleId] = queryKey;
  const url = Constants.DESCRIBE_VEHICLE.replace(":id", vehicleId);
  return await axios.get(url);
};

export const getVehicleGroupDetailsById = async ({ queryKey }) => {
  const [_, groupId] = queryKey;
  const url = Constants.DESCRIBE_VEHICLE_GROUP.replace(":groupId", groupId);
  return await axios.get(url);
};

export const getVehicleArnDetails = async (vehicleId) => {
  const url = Constants.DESCRIBE_VEHICLE.replace(":id", vehicleId);
  return await axios.get(url);
};

export const getDeploymentDetails = async ({ queryKey }) => {
  const [_, deploymentId, vehicleId] = queryKey;
  const url = Constants.DEPLOYMENT_DETAIL_FOR_VEHICLE.replace(
    ":deploymentId",
    deploymentId
  ).replace(":vehicleId", vehicleId);
  return await axios.get(url);
};

export const getJobExecutions = async ({ queryKey }) => {
  const [_, deploymentId] = queryKey;
  let url = Constants.LIST_JOB_EXECUTIONS_FOR_DEPLOYMENT_ID.replace(
    ":id",
    deploymentId
  );
  return await axios.get(url);
};

export const getJobExecutionsForVehicles = async ({ queryKey }) => {
  const [_, deploymentId, vehicleKey] = queryKey;
  let url = Constants.LIST_JOB_EXECUTIONS_FOR_VEHICLE_DEPLOYMENT_ID.replace(
    ":deploymentId",
    deploymentId
  );
  url = url.replace(":vehicleKey", vehicleKey)
  return await axios.get(url);
};

export const getDeploymentAudits = async ({ queryKey }) => {
  const [_, deploymentId, vehicleId] = queryKey;
  let url = Constants.DEPLOYMENT_AUDIT_FOR_VEHICLE.replace(
    ":deploymentId",
    deploymentId
  ).replace(":vehicleId", vehicleId);
  return await axios.get(url);
};

export const getVehicleSubscription = async ({ queryKey }) => {
  const [_, vehicleKey] = queryKey;
  let url = Constants.GET_VEHICLE_SUBSCRIPTIONS.replace(
    ":vehicleKey",
    vehicleKey
  );
  const { data = {} } = await axios.get(url);
  return data;
};

export const getVehicleSubscriptionAudit = async ({ queryKey }) => {
  const [_, vehicleKey] = queryKey;
  let url = Constants.GET_VEHICLE_SUBSCRIPTIONS_AUDIT.replace(
    ":vehicleKey",
    vehicleKey
  );
  const { data = {} } = await axios.get(url);
  return data;
};

export const getVehicleFeaturesAudit = async ({ queryKey }) => {
  const [_, serialNo] = queryKey;
  let url = Constants.GET_VEHICLE_FEATURES_AUDIT.replace(":serialNo", serialNo);
  const { data = {} } = await axios.get(url);
  return data;
};

export const getVehicleShadow = async ({ queryKey }) => {
  const [_, vehicleId = ""] = queryKey;
  const url = Constants.VEHICLE_SHADOW.replace(":id", vehicleId);
  return await axios.get(url);
};

export const getAllFeatures = async () => {
  return await axios.get(Constants.LIST_FEATURES);
};

export const updateVehicleShadow = async (payload) => {
  return await axios.post(Constants.UPDATE_VEHICLE_SHADOW, payload);
};

export const getStatsForVehiclesView = async () => {
  const { data = {} } = await axios.get(Constants.VEHICLES_GET_DASH_STATS);
  return data;
};

export const getVehicleAuditData = async ({ queryKey }) => {
  const [_, serialNo = ""] = queryKey;
  const url = Constants.GET_VEHICLE_AUDIT.replace(":serialNo", serialNo);
  const { data } = await axios.get(url);
  console.log(data);
  return data;
};

export const getStatsForVehicleGroupsView = async () => {
  const { data = {} } = await axios.get(
    Constants.VEHICLE_GROUPS_GET_DASH_STATS
  );
  return data;
};

export const getGroupsAssociatedToVehicle = async ({ queryKey }) => {
  const [_, vehicleKey] = queryKey;
  let url = Constants.GET_GROUPS_ASSOCIATED_TO_VEHICLE.replace(
    ":vehicleKey",
    vehicleKey
  );
  const { data = {} } = await axios.get(url);
  return data;
};
