import React from "react";
import { Card, CardContent, CardHeader, Grid, Box, Alert } from "@mui/material";
import ReleaseGrid from "../components/release/ReleaseGrid";
import ReleaseStats from "../components/release/ReleaseStats";
import { MODULES, MODULE_TEXT } from "../services/Constants";
import { detailsTabsBoxStyle } from "../services/Utils";
import useModule from "../hooks/useModule";
import "../styles/release.css";

export default function Releases() {
  const { validate } = useModule();

  if (!validate(MODULES.OTA)) {
    return <Box {...detailsTabsBoxStyle}>
      <Alert variant="outlined" severity="warning">
        {MODULE_TEXT.OTA} module not enabled for {localStorage.selectedCustomer} customer
      </Alert>
    </Box>
  }

  return (
    <div className="fragmentContainer">
      <Grid container spacing={2}>
        <ReleaseStats />
        <Grid item xs={12} md={12}>
          <Card>
            <CardHeader title="Available Releases"></CardHeader>
            <CardContent sx={{ padding: 0 }}>
              <ReleaseGrid />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
