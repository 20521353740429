import React from "react";
import { createRoot } from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import "@aws-amplify/ui-react/styles.css";
import "./styles/index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import {
  Vehicles,
  VehicleGroups,
  ImageRepofragment,
  Deployments,
  Configurations,
  Logs,
  Users,
  Commands,
  Dashboard,
  Releases,
  Reports,
} from "./fragments";
import reportWebVitals from "./reportWebVitals";
import Image from "./routes/Image";
import Vehicle from "./routes/Vehicle";
import Release from "./routes/Release";
import Deployment from "./routes/Deployment";
import User from "./routes/User";
import { useState } from "react";
import { VehicleDeploymentDetails } from "./components/vehicle";
import { Auth } from "aws-amplify";
import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react";
import { orange, grey } from "@mui/material/colors";

import JwtInterceptor from "./auth/JwtInterceptor";

import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import { WizardProvider } from "./components/wizard/Context";
import { ToastProvider } from "./components/toast/Context";
import ProtectedRoute from "./auth/ProtectedRoute";
import { configureAmplify } from "./auth/AmplifyHelper";
import { AppLogo } from "./components/common/AppLogo";
import { Typography } from "@mui/material";
import VehicleGroup from "./routes/VehicleGroup";
import Configuration from "./routes/Configuration";
import ProvisioningReport from "./components/reports/ProvisioningReport";
import SchedulerReport from "./components/reports/SchedulerReport";
import ValidateRoute from "./routes/ValidateRoute";

console.log("pointing to environment ", process.env.REACT_APP_STAGE);

configureAmplify();

// Add Jwt interceptor to add authorization bearer token in the axios header
JwtInterceptor();

// You can get the current config object
const currentConfig = Auth.configure();
console.log("Other change ::", currentConfig);

export default function AppRouter() {
  const [socket, setSocket] = useState(null);

  /*useEffect(() => {
    const socket = io(Constants.SOCKET_URL);

    socket.on("connect", () => {
      console.log("socket connected");
      console.log(`socket id ${socket.id}`);
      const engine = socket.io.engine;
      console.log(engine.transport.name); // in most cases, prints "polling"
    });

    socket.on("disconnect", () => {
      console.log(`socket disconnected ${socket.id}`);
    });

    setSocket(socket);
    return () => socket.close();
  }, [setSocket]);*/

  const amplifyTheme = {
    name: "my-theme",
    tokens: {
      components: {
        button: {
          // this will affect the font weight of all button variants
          fontWeight: { value: "{fontWeights.black.value}" },
          // style the primary variation
          primary: {
            backgroundColor: { value: "#ef6c00" },
            _hover: {
              backgroundColor: { value: orange[700] },
            },
          },
        },
      },
    },
  };

  // Create a client
  const queryClient = new QueryClient();
  const queryDebugger = process.env.REACT_APP_DEBUGGER || false;

  const AuthPageHeader = () => {
    return (
      <div style={{ marginBottom: "3em" }}>
        <AppLogo />
      </div>
    );
  };

  const AuthViewComponents = {
    Header() {
      return <AuthPageHeader />;
    },
    // SignIn: {
    //   Header() {
    //     return <AuthPageHeader />;
    //   },
    // },
    // SignUp: {
    //   Header() {
    //     return <AuthPageHeader />;
    //   },
    // },
  };

  return (
    <CssBaseline>
      <ToastProvider>
        <QueryClientProvider client={queryClient}>
          <AmplifyProvider theme={amplifyTheme}>
            <Authenticator
              loginMechanisms={["email"]}
              signUpAttributes={["name", "email", "phone_number"]}
              className="auth-container"
            // components={AuthViewComponents}
            >
              {({ signOut, user }) => (
                <BrowserRouter>
                  <WizardProvider>
                    <Routes>
                      <Route path="*" element={<ValidateRoute user={user}><App /></ValidateRoute>}>
                        <Route exact path="/*" element={<Dashboard />} />
                        <Route path="vehicles" element={<Vehicles />} />
                        <Route
                          path="vehicles/:vehicleId/*"
                          element={<Vehicle socket={socket} />}
                        />
                        <Route path="groups" element={<VehicleGroups />} />
                        <Route
                          path="groups/:groupId/*"
                          element={<VehicleGroup />}
                        />
                        <Route path="releases" element={<Releases />} />
                        <Route
                          path="releases/:releaseId/*"
                          element={<Release />}
                        />
                        <Route path="images" element={<ImageRepofragment />} />
                        <Route path="images/:imageId/*" element={<Image />} />
                        <Route path="deployments" element={<Deployments />} />
                        <Route
                          path="deployments/:deploymentId/*"
                          element={<Deployment />}
                        />
                        <Route
                          path="deployments/:deploymentId/vehicle/:vehicleId"
                          element={<VehicleDeploymentDetails />}
                        />
                        <Route
                          path="configurations"
                          element={<Configurations />}
                        />
                        <Route
                          path="custom-configurations"
                          element={<Configurations />}
                        />
                        <Route
                          path="custom-configurations/:configId/*"
                          element={<Configuration />}
                        />
                        <Route path="commands" element={<Commands />} />
                        <Route path="logs" element={<Logs />} />
                        <Route
                          path="users/myprofile/*"
                          element={<User isMyProfile={true} />}
                        />
                        <Route
                          path="users/*"
                          element={
                            <ProtectedRoute>
                              <Users isMyProfile={false} user={user} />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="provisioning-report"
                          element={<ProvisioningReport />}
                        />
                        <Route
                          path="scheduler-report"
                          element={<SchedulerReport />}
                        />
                        <Route
                          path="*"
                          element={
                            <main style={{ padding: "1rem" }}>
                              <p>There's nothing here!</p>
                            </main>
                          }
                        />
                      </Route>
                    </Routes>
                    {queryDebugger && (
                      <ReactQueryDevtools initialIsOpen={false} />
                    )}
                  </WizardProvider>
                </BrowserRouter>
              )}
            </Authenticator>
          </AmplifyProvider>
        </QueryClientProvider>
      </ToastProvider>
    </CssBaseline>
  );
}

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
