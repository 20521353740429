import React, { useEffect, useState } from "react";
import { publishDeployment, cancelDeployment } from "../../services/deployment";
import { useQueryClient, useMutation } from "react-query";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import { HOURS24_TO_MILLISECONDS } from "../../services/Constants";

const PublishDeployment = ({ data, listView = true }) => {
  const { row: { deploymentId, deploymentStatus, campaignEndDate } = {} } =
    data || {};
  // Access the client
  const queryClient = useQueryClient();
  const [disabled, setDisabled] = useState(false);

  // Mutations
  const { mutate: handlePublish, isLoading } = useMutation(publishDeployment, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries("deployments");
    },
  });

  const { mutate: handleCancel, isLoading: cancelIsLoading } = useMutation(
    cancelDeployment,
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries("deployments");
      },
    }
  );

  useEffect(() => {
    var eta_ms = new Date(campaignEndDate).getTime() - Date.now();
    if (eta_ms >= HOURS24_TO_MILLISECONDS) return;
    var timeout = setTimeout(() => {
      setDisabled(true);
    }, eta_ms);
    return () => clearTimeout(timeout);
  }, [campaignEndDate]);

  return (
    <div>
      {listView && (
        <Button
          size="small"
          component={RouterLink}
          to={`/Deployments/${deploymentId}`}
        >
          Details
        </Button>
      )}
      {deploymentStatus === "NOT_PUBLISHED" && (
        <Check I={CAN.PUBLISH} a={SUBJECTS.DEPLOYMENT}>
          <>
            {listView && <span>|</span>}
            <Button
              size="small"
              onClick={() => handlePublish(deploymentId)}
              disabled={isLoading || disabled}
            >
              {isLoading ? <HourglassTopIcon /> : "Publish"}
            </Button>
          </>
        </Check>
      )}
      {deploymentStatus === "PUBLISHED" && (
        <Check I={CAN.CANCEL} a={SUBJECTS.DEPLOYMENT}>
          <>
            {listView && <span>|</span>}
            <Button
              size="small"
              onClick={() => handleCancel(deploymentId)}
              disabled={cancelIsLoading}
            >
              {cancelIsLoading ? <HourglassTopIcon /> : "Cancel"}
            </Button>
          </>
        </Check>
      )}
    </div>
  );
};

PublishDeployment.defaultProps = {
  data: {},
};

PublishDeployment.propTypes = {};

export default PublishDeployment;
