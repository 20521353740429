import React from "react";
import { Card, CardHeader, CardContent, Grid, Box, Alert } from "@mui/material";
import { ImageStats, ImageUploadDataGrid } from "../components/image";
import { MODULES, MODULE_TEXT } from "../services/Constants";
import { detailsTabsBoxStyle } from "../services/Utils";
import useModule from "../hooks/useModule";

const ImageRepofragment = () => {
  const { validate } = useModule();

  if (!validate(MODULES.OTA)) {
    return <Box {...detailsTabsBoxStyle}>
      <Alert variant="outlined" severity="warning">
        {MODULE_TEXT.OTA} module not enabled for {localStorage.selectedCustomer} customer
      </Alert>
    </Box>
  }

  return (
    <div className="fragmentContainer">
      <Grid container spacing={2}>
        <ImageStats />
        <Grid item xs={12} md={12}>
          <Card>
            <CardHeader title="Available Images"></CardHeader>
            <CardContent sx={{ padding: 0 }}>
              <ImageUploadDataGrid />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default ImageRepofragment;
