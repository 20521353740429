import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import Constants from "../services/Constants";

const OTAPagination = (props) => {
  const {
    endpointFn,
    queryName,
    sortByDefault,
    columns,
    extraFilters,
    exportRowsData,
    fetchAll = false
  } = props;
  const [data, setData] = useState({
    loading: true,
    rows: [],
    totalRows: 0,
    rowCount: 0,
    pageSize: Constants.PAGE_SIZE,
    page: 1,
    field: sortByDefault,
    sort: "desc",
    filterValue: {},
  });

  const { filterValue = {}, page, pageSize, field, sort, rowCount } = data;

  //Queries
  const { data: { data: { res: paginationData = [] } = {} } = {}, isLoading } =
    useQuery(
      [
        queryName,
        page,
        pageSize,
        field,
        sort,
        filterValue,
        columns,
        extraFilters ? extraFilters : [],
        fetchAll
      ],
      endpointFn,
      {
        onSuccess: (response) => {
          const { data: { meta = {} } = {} } = response;
          setData({
            ...data,
            rowCount: meta.itemCount,
          });
          exportRowsData?.(response?.data?.res || []);
        },
      }
    );

  const handleSortModelChange = (newModel) => {
    let sortData = newModel[0] ?? { field: sortByDefault, sort: "desc" };
    setData({ ...data, ...sortData });
  };

  const onFilterChange = (filterValue) => {
    setData({
      ...data,
      filterValue,
    });
  };

  return (
    <DataGrid
      initialState={{
        sorting: {
          sortModel: [{ field: sortByDefault, sort: "desc" }],
        },
      }}
      pageSize={pageSize}
      rowsPerPageOptions={Constants.ROWS_PER_PAGE_OPT}
      pagination
      sx={{ minHeight: 700 }}
      loading={isLoading}
      rowCount={rowCount}
      page={page - 1}
      rows={paginationData}
      onPageChange={(page) => {
        setData({
          ...data,
          page: page + 1,
        });
      }}
      onPageSizeChange={(pageSize) => {
        setData({
          ...data,
          page: 1,
          pageSize,
        });
      }}
      onSortModelChange={handleSortModelChange}
      filterValue={filterValue}
      onFilterModelChange={onFilterChange}
      {...props}
    />
  );
};

/***
  The Following props are from the parent components and it differs

  columns
  onSelectionModelChange
  getRowId
  paginationMode
  checkboxSelection
***/

OTAPagination.defaultProps = {
  paginationMode: "server",
  sortByDefault: "createdAt",
  checkboxSelection: false,
  fetchAll: false,
  endpointFn: () => { },
  queryName: "",
};

export default OTAPagination;
