import React, { useState, useContext } from "react";
import { CssBaseline } from "@mui/material";
import { Outlet } from "react-router-dom";
import ResponsiveAppBar from "./appbar/NewResponsiveAppBar";
import { Box } from "@mui/system";
import { CustomThemeProvider } from "./components/themes/CustomThemeProvider";
import WorkflowWizard from "./components/wizard/WorkflowWizard";
import { WizardContext } from "./components/wizard/Context";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./styles/common.css";
import "./styles/wizard.css";
import { SideNav } from "./components/common/SideNav";

function App() {
  const [openDrawer, setOpenDrawer] = useState(true);
  const { state } = useContext(WizardContext);
  const {
    showWizard = false,
  } = state;
  const onAppBarToggle = () => setOpenDrawer(!openDrawer);

  const sideNavProps = { openDrawer, setOpenDrawer };

  return (
    <CustomThemeProvider>
      <CssBaseline />
      {showWizard && <WorkflowWizard />}
      <div className="container">
        <ResponsiveAppBar onToggle={onAppBarToggle}></ResponsiveAppBar>
        <SideNav {...sideNavProps} />
        <Box
          className="contentContainer"
          sx={{
            ml: openDrawer ? 30 : 8,
            mt: 5,
            transition: (theme) => {
              return theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              });
            },
          }}
        >
          <Outlet />
        </Box>
      </div>
    </CustomThemeProvider>
  );
}

export default App;
